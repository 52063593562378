@import '../colors.scss';

.farms {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  z-index: 0;
  position: relative;

  & > li.farm {
    display: block;
    position: relative;
    flex: 45% 0;
    margin: 20px;
    border-radius: 10%;

    background-color: rgba(0,0,0,.2);

    cursor: pointer;

    @media screen and (max-width: 992px) {
      flex: 100%;
      margin: 50px;
    }

    & > img {
      border-radius: 10%;
      max-width: 100%;

      &.done {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.80;
        z-index: 1;
        filter: invert(100%);
      }
    }
  }
}

.zoomedFarm {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.45);

  cursor: default;
  overflow: auto;
  overscroll-behavior: contain;

  &.dark {
    color: darken($lightText, 10%);
    > .zoomedFarmModal > .sidebar {
      background-color: $darkBackground;
    }

    button {
      background-color: darkRed;
    }
  }

  > .zoomedFarmModal {
    height: 800px;
    width: 1100px;
    margin: 2em auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;


    > a.close {
      z-index: 2;
      position: absolute;
      left: calc(50% - 550px); // 600px being half the width of the modal
      font-size: 1.5em;
      font-weight: bold;
      margin: 1%;
      cursor: pointer;
      height: 33px;
      width: 33px;

      &:hover {
        background: black;
        color: white;
        border-radius: 10px;
      }
    }

    > .sidebar {
      font-size: 110%;
      padding: 0 1em;
      height: 800px;
      display: inline-block;
      background-color: white;

      form > select:first-child {
        width: 68%;
        display: block;
      }

      .input-group {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        input {
          line-height: 1.8em;

          &:first-child {
            flex-shrink: 2;
          }
        }

        button {
          min-width: 100px;
          cursor: pointer;
        }

      }

      .harvest-controls {
        flex-direction: row-reverse;

        margin-top: 1em;

        button.harvest {
          background-color: green;
        }
        button.exit {
          background-color: crimson;
        }
      }

      .farm-details {
        margin-top: 1em;
        margin-left: 1.5em;
        font-size: 0.8em;

        a {
          color: $darkText;
        }

        dl {
          display: flex;
          flex-wrap: wrap;
          text-align: left;

          margin-left: 20px;

          dt {
            width: 33%;
          }

          dd {
            margin-left: auto;
            width: 66%;
          }
        }
      }
    }

    > img {
      height: 100%;
      display: block;
    }
  }
}
