@import './colors.scss';

h1 {
    font-size: 3em;
}

h1, h2 {
    color: darken($farmBrown, 10%);
}

p, ol {
  font-size: 1.5em;
  color: rgba($farmBrown, 0.65);

  & > a {
    color: rgba($farmBrown, 0.65);
  }
}

@keyframes dimming {
  from {
    background-color: $lightBackground;
    color: $darkText;
  }
  to {
    background-color: $darkBackground;
    color: $lightText;
  }
}

.App {
  text-align: center;
  background-color: $lightBackground;

  &.dead {
    background-color: $darkBackground;
    animation: dimming 5s;
  }

  & > * {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

button {
    background-color: lighten($farmBrown, 10%);
    border-radius: 10px;
    border: none;
    height: 3em;
    color: $lightText;
    margin: 0 0.5em;
    cursor: pointer;
}

header nav {
  display: flex;
  justify-content: space-between;
  padding: 2em;

  a {
    text-decoration: none;
    color: rgba($farmBrown, 0.5);
    font-size: 1.15em;
    &.active {
      color: $farmBrown;
    }
  }

  ul {
    justify-content: space-between;
    & > li {
      display: inline;
      margin: 1em;
    }
  }

  img.logo {
    height: 50px;
    width: 50px;
  }

  button {
      max-height: 3em;
  }
}

main > footer {
  min-height: 7em;
  margin-top: 7em;
  color: $lightText;
  font-size: 1.2em;
  nav > a {
    color: $lightText;
    text-decoration: none;
    margin: 15px;
  }
}
